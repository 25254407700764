
.developer-pointing-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .developer-pointing-page h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #007bff;
  }
  
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .card {
    position: relative;
    width: 120px;
    height: 180px;
    perspective: 1000px;
    cursor: pointer;
  }
  
  .card-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    border-radius: 10px;
  }
  
  .card.flipped .card-inner {
    transform: rotateY(180deg);
  }
  
  .card-front, .card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .card-front {
    background-color: #007bff;
    color: white;
    font-size: 2em;
    font-weight: bold;
  }
  
  .card-back {
    background-color: #f8f9fa;
    color: #333;
    font-size: 1.2em;
    transform: rotateY(180deg);
  }

  .user-list {
    margin-top: 20px;
  }
  
  .user-list h3 {
    text-align: center;
    color: #007bff;
    margin-bottom: 10px;
  }
  
  .user-list ul {
    list-style-type: none;
    padding: 0;
  }
  
  .user-list li {
    background-color: white;
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }