.facilitator-page {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .facilitator-page h1 {
    text-align: center;
    color: #333;
  }
  
  .facilitator-page h2, .facilitator-page h3 {
    color: #007bff;
  }
  
  .facilitator-page ul {
    list-style-type: none;
    padding: 0;
  }
  
  .facilitator-page li {
    margin: 5px 0;
  }
  
  .session-controls {
    margin-top: 20px;
  }
  
  .session-controls button {
    margin-right: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .session-controls button:hover {
    background-color: #0056b3;
  }
  
  .error {
    color: red;
    font-weight: bold;
    margin-top: 10px;
  }
  