
.border-style {
    max-width: 450px;
    margin: 60px auto;
    padding: 25px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    background-color: #f7f7f7; 
    font-family: 'Arial', sans-serif;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
  }
  
 
  .border-style:hover {
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }
  
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  
  label {
    margin-bottom: 10px;
    font-weight: bold;
    color: #2c3e50; 
  }
  
  
  input,
  select {
    margin-bottom: 20px;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #ccd1d1; 
    font-size: 16px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
 
  input:focus,
  select:focus {
    outline: none;
    border-color: #007bff; 
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.5); 
  }
  
  button {
    padding: 12px 20px;
    background-color: #007bff; 
    color: white; 
    border: 2px solid #0056b3; 
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
  }
  
  
  button:hover {
    background-color: #0056b3; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    border-color: #003f7f; 
  }

  @keyframes buttonAnimation {
     0% { background-color: #353635; }
    15% { background-color: #1f221f; }
    15% { background-color: #353135; }
    
  }
  button{
    animation: buttonAnimation 15s infinite alternate;
  }
  

  button:focus, button:active {
    outline: none;
    border-color: #003f7f; 
    box-shadow: 0 0 10px rgba(0, 56, 128, 0.5); 
  }
  
  @keyframes backgroundAnimation {
    0% { background-color: #bde9b4; }
    15% { background-color: #a7fab5; }
    25% { background-color: #e1bee7; }
    35% { background-color: #f4bafc; }
    45% { background-color: #85d2e0; }
    55% { background-color: #e0d785; }
    65% { background-color: #e085a0; }
    75% { background-color: #e08585; }
    85% { background-color: #85d2e0; }
  }
  
  body {
    animation: backgroundAnimation 15s infinite alternate;
  }
  